




















import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import contadorModule from "@/store/modules/contador-module";
import { contador } from "@/shared/dtos/contador";
import planModule from "@/store/modules/plan-module";
import ambitoModule from "@/store/modules/ambito-module";
import { plan } from "@/shared/dtos/plan";
import { ambito } from "@/shared/dtos/ambito";
import { ClassRadioButtonSource } from "@/components/DinamicallyForm/ClassRadioButtonSource";
import { datatypes } from "@/components/DataTableGenerico/datatypes";

@Component({
  components: {},
})
export default class contadoresFormulario extends Mixins(
  HelperDinamicallyForm
) {
  public item_pantalla: contador = new contador();
  public planes: plan[] = [];
  public ambitos: ambito[] = [];
  public tipo_seleccion: number = 0;

  public async OnCreated() {
    if (this.getparamId().HayParametro) {
      await contadorModule.getcontador(this.getparamId().Parametro);
      this.item_pantalla = contadorModule.contador;
      switch (0) {
        case this.item_pantalla.id_ambitos:
          this.tipo_seleccion = 2;
          break;
        case this.item_pantalla.id_planes:
          this.tipo_seleccion = 1;
          break;
        default:
          this.tipo_seleccion = 0;
          break;
      }
    }
    this.planes = await planModule.getplanes();
    this.ambitos = await ambitoModule.getambitos();
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.digitos,
      "Dígitos",
      "digitos"
    )
      .isRequired()
      .Dimesiones(12, 6, 6)
      .SetValidacion("max_value:4");

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.proximo_numero,
      "Próximo número",
      "proximo_numero"
    )
      .isRequired()
      .SetValidacion("max_value:9999")
      .Dimesiones(12, 6, 6);

    let anchoacerai = this.AddFormItem(
      this.tipo_seleccion,
      "aux_tipo_seleccion",
      "Vincular contador a",
      true,
      50
    );
    let DataSourceRadioButton: ClassRadioButtonSource[] = [];
    DataSourceRadioButton.push(new ClassRadioButtonSource("Plan", 2));
    DataSourceRadioButton.push(new ClassRadioButtonSource("Ambito", 1));
    //anchoacerai.data = "hola2";
    anchoacerai.isRequired(true);
    anchoacerai.CreateRadioButton(DataSourceRadioButton);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_planes,
      "Plan",
      "id_planes"
    )
      .isRequired()
      .CreateComboSingleCodigoDescripcionNames(this.planes, "nombre", "id")
      .Dimesiones(12, 6, 6)
      .isVisible(this.tipo_seleccion === 2);
    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_ambitos,
      "Ambito",
      "id_ambitos"
    )
      .isRequired()
      .CreateComboSingleCodigoDescripcionNames(this.ambitos, "nombre", "id")
      .isVisible(this.tipo_seleccion === 1)
      .Dimesiones(12, 6, 6);
  }

  public Data_tipo_seleccion(object: any) {
    this.tipo_seleccion = object.NewValue;
    this.SetFormItem("id_ambitos").isVisible(this.tipo_seleccion === 1);
    this.SetFormItem("id_planes").isVisible(this.tipo_seleccion === 2);
    switch (this.tipo_seleccion) {
      case 1:
        //Ambito
        this.SetFormItem("id_planes").data = 0;
        break;
      case 2:
        //Planes
        this.SetFormItem("id_ambitos").data = 0;
        break;
    }
  }

  public Insertar(object: contador) {
    contadorModule.guardarcontador(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: contador) {
    contadorModule.modificarcontador(object).then(() => {
      this.AllSaveOk();
    });
  }
}
