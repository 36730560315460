import { store } from "@/store/store";
import { plan } from "@/shared/dtos/plan";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "planModule",
  store,
  dynamic: true,
})
class planModule extends VuexModule {
  public planes: plan[] = [];
  public plan: plan = new plan();

  @Action({ commit: "onGetplanes" })
  public async getplanes() {
    return await ssmHttpService.get(API.plan);
  }

  @Action({ commit: "onGetplan" })
  public async getplan(id: any) {
    return await ssmHttpService.get(API.plan + "/" + id);
  }

  @Action
  public async guardarplan(plan: plan) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.plan, plan.toJson());
  }

  @Action
  public async modificarplan(plan: plan) {
    return await ssmHttpService.put(API.plan + "/" + plan.id, plan);
  }

  @Action
  public async eliminarplan(plan: plan) {
    return await ssmHttpService.delete(API.plan + "/" + plan.id, null, false);
  }

  @Mutation
  public onGetplanes(res: plan[]) {
    this.planes = res ? res.map((x) => new plan(x)) : [];
  }

  @Mutation
  public onGetplan(res: plan) {
    this.plan = new plan(res);
  }
}
export default getModule(planModule);
