import { store } from "@/store/store";
import { contador } from "@/shared/dtos/contador";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "contadorModule",
  store,
  dynamic: true,
})
class contadorModule extends VuexModule {
  public contadores: contador[] = [];
  public contador: contador = new contador();
  public codigo = "";

  @Action({ commit: "onGetcontadores" })
  public async getcontadores() {
    return await ssmHttpService.get(API.contador);
  }

  @Action({ commit: "onGetcontador" })
  public async getcontador(id: any) {
    return await ssmHttpService.get(API.contador + "/" + id);
  }

  @Action({ commit: "onGetcodigo" })
  public async getcodigo(id: any) {
    return await ssmHttpService.get(API.contador + "/contador/" + id);
  }

  @Action({ commit: "onGetcodigo" })
  public async getcontador_ambitos(id: any) {
    return await ssmHttpService.get(API.contador + "/contador_ambitos/" + id);
  }

  @Action({ commit: "onGetcodigo" })
  public async getcontador_planes(id: any) {
    return await ssmHttpService.get(API.contador + "/contador_planes/" + id);
  }


  @Action
  public async guardarcontador(contador: contador) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.contador, contador.toJson());
  }

  @Action
  public async modificarcontador(contador: contador) {
    return await ssmHttpService.put(API.contador + "/" + contador.id, contador);
  }

  @Action
  public async eliminarcontador(contador: contador) {
    return await ssmHttpService.delete(
      API.contador + "/" + contador.id,
      null,
      false
    );
  }

  @Mutation
  public onGetcontadores(res: contador[]) {
    this.contadores = res ? res.map((x) => new contador(x)) : [];
  }

  @Mutation
  public onGetcontador(res: contador) {
    this.contador = new contador(res);
  }
  @Mutation
  public onGetcodigo(res: string) {
    this.codigo = res;
  }
}
export default getModule(contadorModule);
