import { store } from "@/store/store";
import { ambito } from "@/shared/dtos/ambito";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "ambitoModule",
  store,
  dynamic: true,
})
class ambitoModule extends VuexModule {
  public ambitos: ambito[] = [];
  public ambito: ambito = new ambito();

  @Action({ commit: "onGetambitos" })
  public async getambitos() {
    return await ssmHttpService.get(API.ambito);
  }

  @Action({ commit: "onGetambito" })
  public async getambito(id: any) {
    return await ssmHttpService.get(API.ambito + "/" + id);
  }

  @Action
  public async guardarambito(ambito: ambito) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.ambito, ambito.toJson());
  }

  @Action
  public async modificarambito(ambito: ambito) {
    return await ssmHttpService.put(API.ambito + "/" + ambito.id, ambito);
  }

  @Action
  public async eliminarambito(ambito: ambito) {
    return await ssmHttpService.delete(
      API.ambito + "/" + ambito.id,
      null,
      false
    );
  }

  @Mutation
  public onGetambitos(res: ambito[]) {
    this.ambitos = res ? res.map((x) => new ambito(x)) : [];
  }

  @Mutation
  public onGetambito(res: ambito) {
    this.ambito = new ambito(res);
  }
}
export default getModule(ambitoModule);
